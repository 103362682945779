import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GlassImage, IceImage, RecipeIngredientItem } from "../components/recipe";
import "../styles/recipe.css";

const RecipePage = ({ pageContext }) => {

    const [recipe] = React.useState(pageContext.recipe);

    const Items = (i) => {
        const items = i.filter((item) => item.ingredient.ingredientType !== 2);
        return items.sort((i1, i2) => i1.ingredient.ingredientType - i2.ingredient.ingredientType)
    }

    const HasGarnishes = () => {
        return recipe.items.findIndex(item => item.ingredient.ingredientType === 2) > -1;
    }

    return (
        <Layout>
            <Seo title={recipe.name} jsonSchema={{
                '@context': "https://schema.org/",
                '@type': "Recipe",
                'name': `${recipe.name} - Cocktail Pantry Search`,
                'description': `Checkout on how to make ${recipe.name}`,
                "author": {
                    "@type": "Person",
                    "name": "Cocktail Pantry Search"
                },
                "recipeCategory": "cocktail",
                "image": "",
                "prepTime": "PT4M",
                "cookTime": "PT2M",
                "recipeInstructions": recipe.preparation
            }} />
            <div className="recipe-page flex justify-center p-8" style={{ backgroundColor: "#fee7b5" }}>
                {
                    recipe.image &&
                    <div>

                    </div>
                }
                <div className="flex flex-col" style={{ width: "500px" }}>
                    <h1 className="uppercase text-center recipe-name hover:cursor-pointer transition delay-50 duration-200 ease-in-out hover:scale-110">
                        {recipe.name}
                    </h1>
                    <span className="text-center ellipsis">...</span>
                    <span className="category mt-4 text-2xl font-semibold inline-block">
                        Ingredients
                    </span>
                    <div className="flex flex-col">
                        {
                            (recipe.items && recipe.items.length > 0) &&
                            Items(recipe.items).map((item) => {

                                return (
                                    <RecipeIngredientItem item={item} key={item.id} />
                                )
                            })
                        }
                    </div>
                    {
                        HasGarnishes() &&
                        <>
                            <span className="mt-4 text-2xl font-semibold inline-block">
                                Garnishes
                            </span>
                        </>
                    }
                    <span className="mt-4 text-2xl font-semibold inline-block category">
                        Preparation
                    </span>
                    <p className="mt-2 preparation">
                        {recipe.preparation}
                    </p>
                    <div className="flex justify-evenly items-center">
                        {
                            (recipe.glassType && recipe.glassType.icon) &&
                            <GlassImage icon={recipe.glassType.icon} name={recipe.glassType.name} />
                        }
                        {
                            (recipe.iceType && recipe.iceType.icon) &&
                            <IceImage icon={recipe.iceType.icon} name={recipe.iceType.name} />
                        }
                    </div>
                </div>

            </div>
        </Layout>
    )
}
export default RecipePage;
